
import { Component, Prop, VModel, Vue } from "vue-property-decorator";

import * as ShortLinkServices from '@/services/ShortServices'
import { Factcheck } from "@/types";
import { ROOT_ACTIONS } from "@/store/actions";

@Component
export default class ImportClaimReview extends Vue {
    @VModel({ required: true }) show!: boolean;

    @Prop({ required: true }) factcheck!: Factcheck;
    name = ''
    addUtm = false
    redirectToPartner = false

    attributes = [{
        key: '',
        value: ''
    }]

    loading = false;

    get target(): string {
        if (this.redirectToPartner) {
            if (this.addUtm) {
                return this.factcheck.Claim?.ClaimReviews[0].url + '?utm_source=Url&utm_medium=social&utm_content=fotoanna&utm_campaign=deCheckers'
            } else {
                return this.factcheck.Claim?.ClaimReviews[0].url || ''
            }
        } else {
            return 'https://decheckers.be/factchecks/' + this.factcheck.id
        }
    }

    async submit() {
        try {
            this.loading = true;
            await ShortLinkServices.CreateShortLink(this.name, this.factcheck.id as string, this.target, {})
            this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
                color: 'success',
                text: 'Short link succesvol aangemaakt'
            })
            this.$emit('refresh')
            this.show = false;
        } catch (error) {
            this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
                color: 'error',
                text: 'Er is iets misgelopen bij het aanmaken van de short link'
            })
        } finally {
            this.loading = false;
        }


    }

}
